import Vue from 'vue'

// axios
import axios from 'axios'

const obj = {
  // site: window.location.origin,
  site: 'https://www.rich6-th.com',
}
// const endpoint = localStorage.getItem('wallet_endpoint')
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // baseURL: 'https://api2.power100th.com/api',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})
axiosIns.interceptors.request.use(
  async config => {
    await axios.post('https://api.richgroup.vip/api/agent/showid', obj)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        localStorage.setItem('wallet_endpoint', 'https://api.richgroup.vip/api')
        localStorage.setItem('logo_path', response.data.logo_path)
        // eslint-disable-next-line no-param-reassign
        config.baseURL = 'https://api.richgroup.vip/api'
      })
      .catch(error => console.log(error))

    // console.log(config.headers.Authorization)

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns
